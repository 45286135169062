export default {
    data    : () => ({
        loading   : false,
        warehouses: [],
        product   : {
            id    : null,
            unit  : {},
            brands: {},
            units : []
        },
        items     : [],
        forms     : {
            quantity      : 0,
            other_expenses: [],
            errors        : {}
        },
    }),
    created() {
        this.loading = true
        this.getData();
    },
    watch:{
        forms:{
            deep:true,
            handler(val){
                if (val.location) {
                    let arr = val.location.split('-')
                    if (arr.length) val.warehouse = parseInt(arr[1])
                }
            }
        },
    },
    computed: {
        total_weight() {
            return this.weight_per_unit * this.forms.quantity
        },
        weight_per_unit() {
            if(!_.isEmpty(this.product)) {
                if(this.product.units.length) {
                    let unit = _.find(this.product.units, u => {
                        return u.id === this.product.base_unit_id
                    })
                    return !_.isEmpty(unit) && !_.isEmpty(unit.pivot) ? unit.pivot.weight : 0
                } else {
                    return 0
                }
            }
            return 0
        },
        total_material_cost() {
            return this.forms.mcpu = this.items.length
                ? _.sumBy(this.items, item => {
                    return item.quantity * item.product.buying_price
                }) : 0
        },
        totalExpenses() {
            this.forms.expense_total = this.forms.other_expenses.length
                ? _.sumBy(this.forms.other_expenses, item => {
                    return item.value ? item.value : 0
                }) : 0
            this.forms.expense_total = this.forms.expense_total * this.forms.quantity
            this.forms.paid          = this.forms.expense_total
            return this.forms.expense_total
        },
        total_cost_goods_per_unit() {
            if(this.forms.quantity) {
                return (Number(this.total_material_cost * this.forms.quantity) + Number(this.totalExpenses)) / this.forms.quantity
            } else {
                return 0
            }
        },
        total_cost() {
            return this.forms.total_cost = this.total_cost_goods_per_unit * Number(this.forms.quantity)
        }
    },
    methods : {
        resetForm() {
            this.forms = {
                quantity      : 0,
                other_expenses: [],
                errors        : {}
            }
        },
        removeExpense(index) {
            this.forms.other_expenses.splice(index, 1);
        },
        addExpense() {
            this.forms.other_expenses.push({label: "", value: null});
            let indexValue = this.forms.other_expenses.length - 1;
            this.$nextTick(() => this.$refs[`${"exp" + indexValue}`][0].focus());
        },
        remainingQuantity(item) {
            let remainingQuantity   = 0;
            item.warehouse          = _.find(item.product.warehouses, {
                id: item.warehouse_id,
            });
            remainingQuantity       =
                Number(
                    _.find(item.product.warehouses, {id: item.warehouse_id}).pivot
                        .quantity
                ) -
                Number(this.forms.quantity) * Number(item.quantity);
            item.remaining_quantity = remainingQuantity;
            return remainingQuantity;
        },
        findCanbeBuildWith(totalQuantity, required) {
            return Math.floor(Number(totalQuantity) / Number(required));
        },
        getData() {
            axios
                .get("/api/inventory/product-composite/" + this.$route.params.id)
                .then((res) => {
                    this.warehouses            = res.data.warehouses;
                    this.product               = res.data.product;
                    this.items                 = res.data.items;
                    this.forms.material_detail = res.data.items;
                    this.forms.other_expenses  = this.product.bom;
                    this.forms.unit            = this.product.unit.id;
                    this.forms.id              = this.product.id;
                    this.loading               = false
                })
                .catch((err) => {
                    this.loading = false
                });
        },
        validateData() {
            for (let i = 0; i < this.items.length > 0; i++) {
                if(this.items[i].warehouse === undefined || !this.items[i].warehouse) {
                    Swal.fire({
                                  icon: "error",
                                  text: "You don't have enough material to build",
                              });
                    return false;
                }
                if(this.items[i].warehouse && !_.isEmpty(this.items[i].warehouse)) {
                    // console.log(this.items[i].warehouse['pivot'].quantity);
                    if(this.items[i].quantity > this.items[i].warehouse.pivot.quantity) {
                        Swal.fire({
                                      icon: "error",
                                      text: "You don't have enough material to build",
                                  });
                        return false;
                    }
                }
                if(this.items[i].remaining_quantity < 0) {
                    Swal.fire({
                                  icon: "error",
                                  text: "You don't have enough material to build",
                              });
                    return false;
                }
            }
            return true;
        },
        buildBom() {
            let checkValidation = this.validateData();
            if(!checkValidation) return false;
            this.forms.errors       = {}
            this.forms.total_weight = this.total_weight
            this.loading            = true
            axios
                .post("/api/inventory/product-composite-build", this.forms)
                .then((result) => {
                    Swal.fire({
                                  icon : result.data.type,
                                  text : result.data.message,
                                  timer: 3000
                              }).then((result) => {
                        this.resetForm()
                        this.getData()
                        this.loading = false
                    });
                })
                .catch((err) => {
                    this.forms.errors = err.response.data.errors
                    this.loading      = false
                });
        },
        findUnitName(unit, id) {
            return _.find(unit, {id: Number(id)});
        },
        find_bom_type_from_product(item) {
            return !!item.bill_of_materials.length;
        },
    },
};